<span #trigger="cdkOverlayOrigin" (click)="$event.stopImmediatePropagation()"
      (mouseenter)="isOpen = true"
      (mouseleave)="isOpen = false" (touchcancel)="isOpen = false"
      (touchend)="isOpen = false" (touchstart)="isOpen = true"
      cdkOverlayOrigin class="disable-text-selection">
    <ng-content></ng-content>
</span>
<ng-template
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayOrigin]="trigger"
  cdkConnectedOverlay>
  <mat-card class="status-history-popover">
    <mat-card-content>
      <div>
        {{ equipment.created.at | date:'y-MM-dd HH:mm' }} created by
        <app-user [email]="equipment.created.by"></app-user>
      </div>
      <div *ngFor="let hist of equipment.statusHistory">
        {{ hist.authored.at | date:'y-MM-dd HH:mm' }}
        {{ hist.status | readable }}
        by
        <app-user [email]="hist.authored.by"></app-user>
      </div>
      <div *ngIf="equipment.statusHistory.length == 0">
        Sorry, status history is missing for this one
      </div>
      <div>
        {{ equipment.updated.at | date:'y-MM-dd HH:mm' }} last updated by
        <app-user [email]="equipment.updated.by"></app-user>
      </div>
    </mat-card-content>
  </mat-card>
</ng-template>
