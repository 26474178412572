import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {concat, Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {
  Equipment,
  EquipmentBulkUpdateRequest,
  EquipmentCreateUpdateRequest,
  EquipmentGroup,
  EquipmentListResponse,
  EquipmentMoveToRequest,
  EquipmentStatus,
  EquipmentSuggestResponse
} from "./model/equipment";

@Injectable()
export class EquipmentService {
  constructor(private http: HttpClient) {
  }

  list(
    group: EquipmentGroup = EquipmentGroup.PROJECT, projectIds: string[] = [], accountingIds: string[] = [], text: string = "",
    statuses: string[] = [], responsible: string[] = [], manufacturers: string[] = [], serialNumbers: string[] = [],
    includeChildrenProjects: boolean = true,
  ): Observable<EquipmentListResponse> {
    return this.http.get<EquipmentListResponse>(environment.apiUrl + "/equipment", {
      params: {
        group: group,
        projectId: projectIds,
        accountingId: accountingIds,
        text: text,
        status: statuses,
        responsible: responsible,
        manufacturer: manufacturers,
        serialNumber: serialNumbers,
        includeChildrenProjects: includeChildrenProjects,
      }
    })
  }

  findBySerialNumber(serialNumber: string): Observable<EquipmentListResponse> {
    return this.list(EquipmentGroup.PROJECT, [], [], "", [], [], [], [serialNumber])
  }

  suggest(projectId: string): Observable<EquipmentSuggestResponse> {
    return this.http.get<EquipmentSuggestResponse>(environment.apiUrl + "/equipment/suggest", {
      params: {
        projectId: projectId,
      }
    });
  }

  create(projectId: string, request: EquipmentCreateUpdateRequest): Observable<Equipment> {
    return this.http.post<Equipment>(environment.apiUrl + "/equipment?projectId=" + projectId, request)
  }

  delete(id: string): Observable<any> {
    return this.http.delete(environment.apiUrl + "/equipment/" + id)
  }

  update(id: string, request: EquipmentCreateUpdateRequest): Observable<Equipment> {
    return this.http.put<Equipment>(environment.apiUrl + "/equipment/" + id, request)
  }

  bulkUpdate(request: EquipmentBulkUpdateRequest): Observable<any> {
    return this.http.put<Equipment>(environment.apiUrl + "/equipment/bulk", request)
  }

  assign(request: EquipmentMoveToRequest): Observable<any> {
    return this.http.post<Equipment>(environment.apiUrl + "/equipment/assign", request);
  }

  moveTo(request: EquipmentMoveToRequest): Observable<any> {
    return this.http.post<Equipment>(environment.apiUrl + "/equipment/move", request)
  }

  copy(equipment: Equipment[], copyToProjectId: string): Observable<any> {
    return concat(
      ...equipment.map(e => {
        const request = {
          manufacturer: e.manufacturer,
          sku: e.sku,
          model: e.model,
          description: e.description,
          hyperlink: e.hyperlink,
          quantity: e.quantity,
          status: EquipmentStatus.PENDING_ORDER,
          group: EquipmentGroup.PROJECT,
        } as EquipmentCreateUpdateRequest;
        return this.create(copyToProjectId, request);
      })
    );
  }
}
