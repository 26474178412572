import {Component, ElementRef, HostListener, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {catchError, combineLatest, distinctUntilChanged, Observable, of, startWith, switchMap} from "rxjs";
import {EquipmentService} from "../api/equipment.service";
import {ProjectService} from "../api/project.service";
import {RmaService} from "../api/rma.service";
import {debounceTime, map} from "rxjs/operators";
import {FormControl} from "@angular/forms";
import {EquipmentGroup} from "../api/model/equipment";
import {Router} from "@angular/router";
import {projectUrl} from "../project/url.pipe";

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class GlobalSearchComponent {
  @ViewChild('search') searchElement!: ElementRef;

  searchResults: Observable<(SearchResultGroup | null)[]>;
  searchControl: FormControl = new FormControl([""]);

  constructor(
    private projectService: ProjectService,
    private equipmentService: EquipmentService,
    private rmaService: RmaService,
    private router: Router,
  ) {
    this.searchResults = this.searchControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap(val => {
          return combineLatest([
            this._performEquipmentSearch(val || ''),
            this._performProjectSearch(val || ''),
            this._performRmaSearch(val || ''),
          ])
        })
      );
  }

  @HostListener('window:keydown', ['$event'])
  detectGlobalHotkey($event: KeyboardEvent) {
    if ($event.key == "/") {
      console.log("GlobalSearchComponent / input detected", $event);
      if (!($event.target instanceof HTMLInputElement || $event.target instanceof HTMLTextAreaElement)) {
        $event.preventDefault();
        this.searchElement.nativeElement.focus();
      }
    }
  }

  searchOptionSelected($event: MatAutocompleteSelectedEvent) {
    const selectionValue = $event.option.value;
    if (selectionValue.length > 0) {
      this.searchControl.setValue("", {emitEvent: true});
      this.router.navigateByUrl($event.option.value);
    }
  }

  private _performEquipmentSearch(query: string): Observable<SearchResultGroup | null> {
    return query.length === 0 ? of(null) : this.equipmentService.list(EquipmentGroup.PROJECT, [], [], query)
      .pipe(map(value => {
        const matches = value.equipment.length;
        if (matches > 0) {
          return {
            label: `Equipment view ${matches} result${matches > 1 ? "s" : ""}`,
            value: `/equipment?q=${encodeURIComponent(query)}`,
            items: undefined,
          };
        } else {
          return {
            label: "Equipment not found",
            value: "",
            items: undefined,
          };
        }
      }));
  }

  private _performProjectSearch(query: string): Observable<SearchResultGroup | null> {
    return query.length === 0 ? of(null) : this.projectService.list(query)
      .pipe(map(projects => {
        const matches = projects.length;
        if (matches > 0) {
          return {
            label: `Projects ${matches}`,
            value: `/equipment?q=${encodeURIComponent(query)}`,
            items: projects.map(p => {
              return {
                label: `${p.name}`,
                value: projectUrl(p)
              }
            }),
          };
        } else {
          return {
            label: "Projects not found",
            value: "",
            items: undefined,
          };
        }
      }));
  }

  private _performRmaSearch(query: string): Observable<SearchResultGroup | null> {
    return query.length === 0 ? of(null) : this.rmaService.list(query)
      .pipe(
        map(rmas => {
          const matches = rmas.length;
          if (matches > 0) {
            return {
              label: `RMAs ${matches}`,
              value: undefined,
              items: rmas.map(r => {
                return {
                  label: `${r.rmaNumber}`,
                  value: `/service/${r.rmaNumber}`
                }
              }),
            };
          } else {
            return {
              label: "RMAs not found",
              value: "",
              items: undefined,
            };
          }
        }),
        catchError((err, caught) => {
          console.log("GlobalSearchComponent RMA err caught", err, caught);
          return of(null);
        }),
      );
  }

}

interface SearchResultGroup {
  label: string;
  items: SingleSearchResult[] | undefined;
  value: string | undefined;
}

interface SingleSearchResult {
  label: string;
  value: string;
}
