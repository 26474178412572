import {Component, effect} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from "@angular/router";
import {AuthService} from "../../api/auth.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent {
  passwordHidden = true;
  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(5)])
  })

  constructor(private router: Router, private authService: AuthService) {
    effect(() => {
      if (authService.isLoggedInSignal()?.value) {
        console.log("LoginComponent isLoggedInSignal effect true");
        router.navigate(["/"]);
      }
    });
    authService.getCurrentUser().subscribe(currUser => {
      console.log("LoginComponent currUser", currUser);
    });
  }

  onSubmit() {
    this.authService.login(this.loginForm.value["email"] || "", this.loginForm.value["password"] || "").subscribe((loginSuccess: boolean) => {
        console.log("LoginComponent login finished", loginSuccess);
        if (loginSuccess) {
          this.router.navigate(["/"]);
        } else {
          this.loginForm.setErrors({"credentials": true})
        }
      }
    )
  }
}
