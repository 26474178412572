import {inject} from '@angular/core';
import {CanActivateChildFn, CanActivateFn, Router, UrlTree} from '@angular/router';
import {AuthService} from "../api/auth.service";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";

export const authGuard: CanActivateFn | CanActivateChildFn = (): Observable<UrlTree | boolean> => {
  const authService = inject(AuthService);
  const router = inject(Router);

  /*return toObservable(authService.isLoggedInSignal)
    .pipe(map(loggedIn => loggedIn?.value === true || router.parseUrl('/login')));*/
  return authService.getCurrentUser().pipe(map(user => user != null || router.parseUrl('/login')));
};
