import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {Project, ProjectStatus, ProjectType, removePrefix, typeToPrefix} from "../../api/model/project";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../api/auth.service";
import {ProjectService} from "../../api/project.service";
import {fromEvent} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {
  ProjectAddEditDialogComponent,
  ProjectAddEditDialogData
} from "../add-edit-dialog/project-add-edit-dialog.component";
import _ from "lodash";

@Component({
  selector: 'app-project-list-view',
  templateUrl: './project-list-view.component.html',
  styleUrls: ['./project-list-view.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectListViewComponent implements OnInit, AfterViewInit {
  projectGroups: AccountingIdGroup[] | undefined;
  bookmarked: string[] = [];
  filterLoading: boolean = false;
  filterValue: string = "";
  loadedByFilter: string = "";
  statuses = Object.values(ProjectStatus);
  projectType: ProjectType = ProjectType.PUBLIC;
  @ViewChild("filter") filterField!: ElementRef;
  filterStatuses: ProjectStatus[] = [];
  expanded: boolean = false;
  orderBy: string;
  orderDirection: string;
  private firstLoad = true;
  private previousAccountingId: string | undefined;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private projectService: ProjectService) {
    this.orderBy = localStorage.getItem(`projectListOrderBy`) || "accountingId";
    this.orderDirection = localStorage.getItem(`projectListOrderDirection`) || "desc";
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.projectType = data["type"];
      this.loadProjects();
      this.loadBookmarks();
    })
  }

  ngAfterViewInit() {
    const keyUpEvent = fromEvent(this.filterField.nativeElement, 'keyup')
    keyUpEvent.subscribe(_ => {
      if (this.filterValue != this.loadedByFilter) {
        this.filterLoading = true;
      }
    })
    keyUpEvent
      .pipe(debounceTime(500)).subscribe(c => {
      if (this.filterValue != this.loadedByFilter) {
        this.loadProjects()
      }
    });
  }

  loadProjects() {
    this.filterLoading = true;
    let filter = this.filterValue;
    const prefix = this.prefix();
    this.projectService.list(filter, undefined, this.projectType, this.filterStatuses, this.orderBy, this.orderDirection).subscribe(
      projects => {
        this.loadedByFilter = filter;
        this.projectGroups = this.projectListToGroups(projects);
        this.filterLoading = false;
        this._changeDetectorRef.markForCheck();
        this.expanded = filter.length > 0 || this.filterStatuses.length > 0;
        if (this.firstLoad) {
          this.firstLoad = false;
          this.previousAccountingId = projects.map(p => removePrefix(prefix, p.accountingId)).filter(a => a?.match(/^\d/)).sort().reverse()[0];
          console.log("ProjectListViewComponent loadProjects firstLoad previousAccountingId", this.previousAccountingId);
        }
      })
  }

  loadBookmarks() {
    this.authService.getCurrentUser().subscribe(user => {
      this.bookmarked = user?.bookmarkedProjects || [];
    })
  }

  openDialog(): void {
    const previousAccountingId = this.previousAccountingId;
    const nextAccountingId = this.previousAccountingId ? String(Number(this.previousAccountingId) + 1).padStart(4, "0") : undefined;
    const data: ProjectAddEditDialogData = {
      project: undefined, type: this.projectType,
      nextAccountingId: nextAccountingId, previousAccountingId: previousAccountingId,
    }
    const dialogRef = this.dialog.open(ProjectAddEditDialogComponent, {
      data: data,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const project = result as Project
        this.router.navigate(["/project/" + project.accountingId + "/" + project.id])
      }
    });
  }

  clearFilter() {
    this.filterValue = '';
    this.loadProjects();
  }

  isFilterEmpty(): boolean {
    return this.loadedByFilter.length == 0;
  }

  onStatusChange() {
    this.loadProjects();
  }

  onOrderChange() {
    localStorage.setItem(`projectListOrderBy`, this.orderBy);
    localStorage.setItem(`projectListOrderDirection`, this.orderDirection);
    this.loadProjects();
  }

  private prefix(): string {
    return typeToPrefix(this.projectType);
  }

  private projectListToGroups(projects: Project[]): AccountingIdGroup[] {
    return _.chain(projects)
      .groupBy(p => p.accountingId)
      .map((projects, accountingId) => ({accountingId, projects}))
      .value();
  }
}

interface AccountingIdGroup {
  accountingId: string;
  projects: Project[];
}
