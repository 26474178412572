import {Project, ProjectType} from "./project";
import {Authored} from "./common";
import {Equipment} from "./equipment";

export interface AlertGrouped {
  severity: AlertSeverity;
  type: AlertType;
  message: string;
  project: ProjectInfo;
  snoozedUntil: Date | null;

  culpritEquipmentIds: string[];
  alertIds: string[];
}

export interface AlertDetailed extends AlertGrouped {
  id: string;
  equipmentId: string;
  projectId: string;
  severity: AlertSeverity;
  type: AlertType;
  message: string;
  users: string[];
  snoozedUntil: Date | null;
  status: AlertStatus;
  created: Authored;
  updated: Authored;
  project: Project;
}

export function isAlertForEquipment(a: AlertGrouped | AlertDetailed, equipment: Equipment | undefined) {
  return equipment && (
    ((a as AlertDetailed).equipmentId == equipment.id) ||
    (a.culpritEquipmentIds && a.culpritEquipmentIds.includes(equipment.id))
  );
}

export interface ProjectInfo {
  id: string;
  type: ProjectType;
  name: string;
  accountingId: string;
}

export enum AlertStatus {
  ACTIVE = "ACTIVE",
  RESOLVED = "RESOLVED",
}

export enum AlertSeverity {
  CRITICAL = "CRITICAL",
  WARNING = "WARNING",
}

export enum AlertType {
  PROJECT_DEADLINE_OVERDUE = "PROJECT_DEADLINE_OVERDUE",
  EQUIPMENT_DFC_NEEDS_ACTION = "EQUIPMENT_DFC_NEEDS_ACTION",
  EQUIPMENT_PENDING_ORDER = "EQUIPMENT_PENDING_ORDER",
  EQUIPMENT_PENDING_INFO_FROM_SUPPLIER = "EQUIPMENT_PENDING_INFO_FROM_SUPPLIER",
  EQUIPMENT_PENDING_DELIVERY_OVERDUE = "EQUIPMENT_PENDING_DELIVERY_OVERDUE",
  EQUIPMENT_SHIPPING_DATE_MISSING = "EQUIPMENT_SHIPPING_DATE_MISSING",
  EQUIPMENT_MISSING_SERIAL_NUMBER = "EQUIPMENT_MISSING_SERIAL_NUMBER",
  EQUIPMENT_READY_TO_SHIP = "EQUIPMENT_READY_TO_SHIP",
}

export interface AlertSnoozeRequest {
  alertIds: string[];
  snoozedUntil: number | undefined | null;
}
