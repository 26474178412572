import {ChangeDetectorRef, Component} from '@angular/core';
import {PasswordResetRequest, UserShortResponse} from "../../api/model/user";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../api/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpErrorResponse} from "@angular/common/http";
import {ApiError} from "../../api/model/common";
import {EMPTY} from "rxjs";

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.sass']
})
export class PasswordResetComponent {
  user: UserShortResponse | undefined;
  passwordResetForm = new FormGroup({
    password: new FormControl("", [Validators.required, Validators.minLength(5)]),
  })
  apiError: string | undefined;
  private emailLinkId: string = "";

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    route.queryParams.subscribe(params => {
      this.emailLinkId = params["id"]
      console.log("PasswordResetComponent emailLinkId", this.emailLinkId)
      authService.getUserByEmailLink(this.emailLinkId).subscribe({
        next: user => {
          this.user = user;
          console.log("PasswordResetComponent user loaded", this.user);
          _changeDetectorRef.markForCheck();
        },
        error: error => this.apiError = ((error as HttpErrorResponse).error as ApiError).message || (error as HttpErrorResponse).message,
      })
    })
  }

  onSubmit() {
    if (this.passwordResetForm.valid) {
      this.apiError = undefined;
      const formData = this.passwordResetForm.getRawValue();

      const request = formData as PasswordResetRequest;
      request.emailLinkId = this.emailLinkId;

      this.authService.resetPassword(request)
        .subscribe({
          next: (user) => {
            console.log("PasswordResetComponent password reset", user);
            this._snackBar.open(`Great success! You can now login with the new password.`,
              undefined, {duration: 10000});
            this.router.navigate(["/"]);
          },
          error: error => {
            console.log("PasswordResetComponent failed to reset", error)
            this.apiError = ((error as HttpErrorResponse).error as ApiError).message || (error as HttpErrorResponse).message;
            return EMPTY;
          }
        });
    }
  }
}
