import {Authored} from "./common";
import {Project} from "./project";

export enum EquipmentStatus {
  PENDING_ORDER = "PENDING_ORDER",
  PENDING_INFO = "PENDING_INFO",
  ORDERED = "ORDERED",
  IN_STOCK = "IN_STOCK",
  READY_TO_SHIP = "READY_TO_SHIP",
  ON_CLIENT_PREMISES = "ON_CLIENT_PREMISES",
  INSTALLED = "INSTALLED",
  DIFFERS_FROM_CONTRACT = "DIFFERS_FROM_CONTRACT",
}

export enum ItemStatus {
  IN_STOCK = "IN_STOCK",
  PDI_DONE = "PDI_DONE",
  READY_TO_SHIP = "READY_TO_SHIP",
  ON_CLIENT_PREMISES = "ON_CLIENT_PREMISES",
  INSTALLED = "INSTALLED",
}

export enum EquipmentStatusNonSelectable {

}

export function isReceived(s: EquipmentStatus | null | undefined): boolean {
  return s != null && s != EquipmentStatus.PENDING_ORDER &&
    s != EquipmentStatus.PENDING_INFO &&
    s != EquipmentStatus.ORDERED &&
    s != EquipmentStatus.DIFFERS_FROM_CONTRACT
}

export function isFinal(s: EquipmentStatus | null | undefined): boolean {
  return s != null && (s == EquipmentStatus.INSTALLED || s == EquipmentStatus.ON_CLIENT_PREMISES || s == EquipmentStatus.DIFFERS_FROM_CONTRACT)
}

export function isPendingOrder(s: EquipmentStatus | null | undefined): boolean {
  return s != null && (
    s == EquipmentStatus.PENDING_ORDER ||
    s == EquipmentStatus.PENDING_INFO
  )
}

export function isPendingOrderOrOrdred(s: EquipmentStatus | null | undefined): boolean {
  return s != null && (
    s == EquipmentStatus.PENDING_ORDER ||
    s == EquipmentStatus.PENDING_INFO ||
    s == EquipmentStatus.ORDERED
  )
}

export function isBeforePreDeliveryInspectionDone(s: EquipmentStatus | null | undefined): boolean {
  return s != null && (
    s == EquipmentStatus.PENDING_ORDER ||
    s == EquipmentStatus.PENDING_INFO ||
    s == EquipmentStatus.ORDERED ||
    s == EquipmentStatus.IN_STOCK
  )
}

export enum DiffFromContract {
  YES_NEEDS_ACTION = "YES_NEEDS_ACTION",
  YES_RESOLVED = "YES_RESOLVED",
}

export interface Equipment {
  id: string;
  group: EquipmentGroup;
  projectId: string;
  assignedProjectId: string | null;
  projectName: string;
  created: Authored;
  updated: Authored;
  comments: Comment[];
  commentIds: string[];
  sku: string;
  manufacturer: string;
  model: string;
  description: string;
  hyperlink: string;
  projectCode: string | null;
  notes: string;
  quantity: number;
  status: EquipmentStatus;
  statusHistory: StatusHistory[];
  responsible: string[];
  responsibleByManufacturer: string[];
  diffFromContract: DiffFromContract | null;
  items: Item[];
  itemCountByStatus: { [key: string]: number }
  estimatedShipByDate: Date | null
  receivedOnDate: Date | null
  purchaseOrderNumber: string | null;
  purchaseOrderDate: Date | null;
  salesOrderConfirmationNumber: string | null;
  salesOrderConfirmationDate: Date | null;
  invoiceNumber: string | null;
  invoiceDate: Date | null;
  installedDate: Date | null;
  warrantyDate: Date | null;
}

export interface StatusHistory {
  status: EquipmentStatus;
  authored: Authored;
}

export interface ItemStatusHistory {
  status: ItemStatus;
  authored: Authored;
}

export enum EquipmentGroup {
  PROJECT = "PROJECT",
  SERVICE = "SERVICE",
}

export interface EquipmentCreateUpdateRequest {
  group: EquipmentGroup;
  sku: string;
  manufacturer: string;
  model: string;
  description: string;
  hyperlink: string;
  projectCode: string;
  quantity: number;
  splitQty: number;
  status: EquipmentStatus;
  responsible: string[];
  diffFromContract: DiffFromContract | null;
  estimatedShipByDate: number | null
  receivedOnDate: number | null
  purchaseOrderNumber: string | null;
  purchaseOrderDate: number | null;
  salesOrderConfirmationNumber: string | null;
  salesOrderConfirmationDate: number | null;
  invoiceNumber: string | null;
  invoiceDate: number | null;
  installedDate: number | null;
  items: Item[] | null;
}

export interface Item {
  serialNumber: string;
  statuses: ItemStatus[];
  statusHistory: ItemStatusHistory[];
  firmwareVersion: string;
}

export interface EquipmentBulkUpdateRequest {
  ids: string[];
  status: EquipmentStatus | undefined | null;
  responsible: string[] | undefined | null;
  estimatedShipByDate: number | undefined | null
  receivedOnDate: number | undefined | null
  purchaseOrderNumber: string | undefined | null;
  purchaseOrderDate: number | undefined | null;
  salesOrderConfirmationNumber: string | undefined | null;
  salesOrderConfirmationDate: number | undefined | null;
  invoiceNumber: string | undefined | null;
  invoiceDate: number | undefined | null;
  installedDate: number | undefined | null;
}

export interface EquipmentMoveToRequest {
  ids: string[];
  projectId: string | null;
}

export interface EquipmentListResponse {
  equipment: Equipment[];
  projects: Project[];
}

export interface EquipmentSuggestResponse {
  previousProjectCode: string | null;
  nextProjectCode: string | null;
}
