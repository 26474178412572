import {Component, Input, OnInit} from '@angular/core';
import {Project, ProjectStatus} from "../../api/model/project";
import {ThemePalette} from "@angular/material/core";

@Component({
  selector: 'app-project-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.sass']
})
export class StatusComponent implements OnInit {
  @Input() project: Project | undefined;
  @Input() condense: Boolean = false;
  @Input() status!: string;
  tooltip!: string;

  ngOnInit(): void {
    if (this.project) {
      this.status = this.project.status;
    }
    this.tooltip = 'Status '
      + this.status;
  }

  iconColor(): ThemePalette {
    return this.status == ProjectStatus.ACTIVE ? 'warn' :
      (this.status == ProjectStatus.COMPLETE ? 'primary' : undefined);
  }

  isYellow(): boolean {
    return this.status == ProjectStatus.PENDING;
  }

}
